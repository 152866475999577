package org.company.app.presentation.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.slideInHorizontally
import androidx.compose.animation.slideOutHorizontally
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.BottomNavigation
import androidx.compose.material.BottomNavigationItem
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.CurrentScreen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import cafe.adriel.voyager.navigator.tab.LocalTabNavigator
import cafe.adriel.voyager.navigator.tab.Tab
import cafe.adriel.voyager.navigator.tab.TabNavigator
import com.jettaskboard.multiplatform.ui.screens.board.appBar.TaskBoardAppBar
import com.mohamedrejeb.compose.dnd.reorder.ReorderContainer
import com.mohamedrejeb.compose.dnd.reorder.ReorderState
import com.mohamedrejeb.compose.dnd.reorder.ReorderableItem
import com.mohamedrejeb.compose.dnd.reorder.rememberReorderState
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import kotlinx.coroutines.launch
import kotlinx.uuid.UUID
import kotlinx.uuid.generateUUID
import kotlinx.uuid.randomUUID
import multiplatform_app.composeapp.generated.resources.*
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.drag_icon
import multiplatform_app.composeapp.generated.resources.x_cross
import org.company.app.data.model.CustomColumn
import org.company.app.data.model.User
import org.company.app.presentation.component.*
import org.company.app.presentation.component.draganddrop.DragDropList
import org.company.app.presentation.component.draganddrop.RedBox
import org.company.app.presentation.component.draganddrop.handleLazyListScroll
import org.company.app.presentation.ui.board.menu.slide.SlideMenu
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.theme.AppTheme
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.CategoryItem
import org.openapitools.client.models.TaskItem
import org.openapitools.client.models.TaskStatusDetailsRequest
import org.openapitools.client.models.TaskStatusDetailsResponse
import kotlin.random.Random

val LocalActiveUser = compositionLocalOf<User>{ error("No user found") }

class HomeScreen(
    val navigateWithRouterCardDetails: () -> Unit={},
    val navigateBackFromCardDetails: () -> Unit={},
    val navigateAfterSignOut: () -> Unit={},
    val taskId: MutableState<String?>? =null) : Screen {
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalResourceApi::class)
    @Composable
    override fun Content() {
        AppTheme {
          //  val windowSizeClass = calculateWindowSizeClass()
         //   val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
            val controlProject = remember { mutableStateListOf<String>("Project") }


            val viewModel = koinInject<HomeViewModel>()
            val homeState by viewModel.homeState.collectAsState()
            val user = homeState.user
            var createWorkspace = homeState.createWorkspace
            val createBoard = homeState.createBoard
            val project = homeState.selectedProject
            val navigator= LocalNavigator.currentOrThrow
            val settings = Settings()
                LaunchedEffect(Unit){
                    if (settings["access_token", ""].isNotEmpty()&&settings["refresh_token",""].isNotEmpty()){
                        viewModel.loadImages()
                        viewModel.getUser {}
                        viewModel.getBoards()
                    }


                }






            TabNavigator(HomeTab( navigateWithRouterCardDetails,navigateBackFromCardDetails,taskId,user?.name?:"")){
                val tabNavigator = LocalTabNavigator.current
              //  if (useNavRail) {
                if (true) {

                        Scaffold (topBar = {
                            Surface(
                                shadowElevation = 2.dp, // play with the elevation values

                            ) {
                                TaskBoardAppBar(
                                    title = "ZiFOCUS", navigator = navigator,
                                    user =user, navigateAfterSignOut = navigateAfterSignOut,
                                    navigateWithRouterCardDetails=navigateWithRouterCardDetails,
                                    navigateBackFromCardDetails=navigateBackFromCardDetails,
                                    )

                            }

                                           }
                            , content = {
                            Box(
                                modifier = Modifier
                                    .padding(it)
                            ) {
                                Row {

                                    AppNavigationRailBar(
                                        navigatorParent = navigator
                                    )
                                    Box {
                                        CurrentScreen()
                                        if (createWorkspace) {
                                            val workspaceName = remember { mutableStateOf(if (homeState.editWorkSpace)project?.projectName?:"" else "") }

                                            //    val viewModel = koinInject<OnBoardingViewModel>()
                                            val createWorkspaceClicked = remember { mutableStateOf(false) }

                                            var columns by remember {
                                                mutableStateOf(if (homeState.editWorkSpace) project?.taskStatusDetails ?: arrayListOf()
                                                else listOf(
                                                    TaskStatusDetailsResponse(name = "To do", id = "1",order=0, considerCompleted = false),
                                                    TaskStatusDetailsResponse(name="In Progress",id = "2",order=1, considerCompleted = false),
                                                    TaskStatusDetailsResponse(name ="Completed",id = "3",order=2, considerCompleted = true)
                                                ))
                                            }

                                            AlertDialog(
                                                onDismissRequest = { viewModel.hideCreateWorkSpaceDialog() },
                                                properties = DialogProperties(
                                                    dismissOnClickOutside = true,
                                                    dismissOnBackPress = true,
                                                    usePlatformDefaultWidth = false

                                                ),
                                                modifier = Modifier.width(415.dp).padding(vertical = 80.dp),
                                                containerColor = Color(0xFFFAFAFA),
                                                title = { Row {
                                                    Spacer(modifier = Modifier.weight(1f))
                                                    Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { viewModel.hideCreateWorkSpaceDialog() })
                                                }
                                                        },
                                                text = {


                                                    Column(modifier = Modifier.fillMaxHeight(), verticalArrangement = Arrangement.Top, horizontalAlignment = Alignment.Start) {
                                                        Text("Workspace",
                                                            fontSize = 24.sp,
                                                            style =  MaterialTheme.typography.bodyMedium, fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF03264A))
                                                        Spacer(modifier = Modifier.height(24.dp))
                                                        ZiFocusTextField(workspaceName,
                                                            textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp,color = Color(0xFF5C728A)),
                                                            placeHolder = "Workspace name"
                                                        )

                                                        Spacer(modifier = Modifier.height(32.dp))
                                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                                            Text("Column",
                                                                fontSize = 16.sp,
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                color = Color(0xFF5C728A).copy(0.5f))
                                                            Spacer(modifier = Modifier.weight(1f))
                                                            Text("Add Column",
                                                                fontSize = 14.sp,
                                                                style =  MaterialTheme.typography.bodySmall,
                                                                fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                                                                color = MaterialTheme.colorScheme.primaryContainer,
                                                                modifier = Modifier.clickable {
                                                                    columns = columns.toMutableList().apply {
                                                                        add(TaskStatusDetailsResponse(name = "", id = null, order = columns.size))
                                                                    }
                                                                })

                                                        }

                                                        Spacer(modifier = Modifier.height(16.dp))

                                                        val scope = rememberCoroutineScope()
                                                        val reorderState = rememberReorderState<TaskStatusDetailsResponse>()


                                                        val lazyListState = rememberLazyListState()

                                                        ReorderContainer(
                                                            state = reorderState,
                                                            modifier = Modifier,
                                                        ) {
                                                            LazyColumn(
                                                                verticalArrangement = Arrangement.spacedBy(20.dp),
                                                                state = lazyListState,
                                                                modifier = Modifier
                                                                    .fillMaxSize()
                                                            ) {
                                                                items(columns, key = { it.id?:UUID.generateUUID(Random) }) { item ->
                                                                    ReorderableItem<TaskStatusDetailsResponse>(
                                                                        state = reorderState,
                                                                        key = item.id?:UUID.generateUUID(Random),
                                                                        data = item,
                                                                        onDrop = {
                                                                                 columns.forEachIndexed { index, customColumn ->
                                                                                     customColumn.apply {
                                                                                         order=index
                                                                                     }
                                                                                 }
                                                                            println(columns.toString())
                                                                        },
                                                                        onDragEnter = { state ->
                                                                            columns = columns.toMutableList().apply {
                                                                                val index = indexOf(item)
                                                                                if (index == -1) return@ReorderableItem
                                                                                remove(state.data)
                                                                                add(index, state.data)

                                                                                scope.launch {
                                                                                    handleLazyListScroll(
                                                                                        lazyListState = lazyListState,
                                                                                        dropIndex = index,
                                                                                    )
                                                                                }
                                                                            }
                                                                        },
                                                                        draggableContent = {
                                                                            Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth().then(

                                                                                    Modifier
                                                                                        .shadow(elevation = 18.dp,)

                                                                            )) {
                                                                                var customColumnName = remember { mutableStateOf(item.name?:"") }
                                                                                Image(painterResource(Res.drawable.drag_icon),null)
                                                                                Spacer(modifier = Modifier.width(16.dp))

                                                                                ZiFocusTextField(value =customColumnName.value,
                                                                                    onValueChange = {
                                                                                        customColumnName.value=it
                                                                                        item.apply { name=it }
                                                                                                    },
                                                                                    textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A)),
                                                                                    placeHolder = "Column name",
                                                                                    modifier = Modifier.fillMaxWidth(0.9f)
                                                                                )
                                                                                Spacer(modifier = Modifier.weight(1f))
                                                                                Image(painterResource(Res.drawable.remove),null, modifier = Modifier.clickable {
                                                                                   // columns.remove(item)
                                                                                })
                                                                            }
                                                                        },
                                                                        modifier = Modifier
                                                                    ) {
                                                                        Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth().graphicsLayer {
                                                                            alpha = if (isDragging) 0f else 1f
                                                                        }) {
                                                                            val customColumnName = remember { mutableStateOf(item.name?:"") }
                                                                            Image(painterResource(Res.drawable.drag_icon),null)
                                                                            Spacer(modifier = Modifier.width(16.dp))

                                                                            ZiFocusTextField(value =customColumnName.value,
                                                                                onValueChange = {
                                                                                    customColumnName.value=it
                                                                                    item.apply { name=it }
                                                                                },
                                                                                textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A)),
                                                                                placeHolder = "Column name",
                                                                                modifier = Modifier.fillMaxWidth(0.9f)
                                                                            )
                                                                            Spacer(modifier = Modifier.weight(1f))
                                                                            Image(painterResource(Res.drawable.remove),null, modifier = Modifier.clickable {
                                                                                // columns.remove(item)
                                                                                columns = columns.toMutableList().apply{
                                                                                    remove(item)
                                                                                }
                                                                            })
                                                                        }

                                                                    }
                                                                }
                                                            }
                                                        }

                                                      /*  LazyColumn {
                                                            items(columns){
                                                                Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()) {
                                                                    val customColumnName = remember { mutableStateOf(it.name) }
                                                                    Image(painterResource(Res.drawable.drag_icon),null)
                                                                    Spacer(modifier = Modifier.width(16.dp))

                                                                    ZiFocusTextField(customColumnName,
                                                                        textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A)),
                                                                        placeHolder = "Column name",
                                                                        modifier = Modifier.fillMaxWidth(0.9f)
                                                                    )
                                                                    Spacer(modifier = Modifier.weight(1f))
                                                                    Image(painterResource(Res.drawable.remove),null, modifier = Modifier.clickable { columns.remove(it) })
                                                                }
                                                                Spacer(modifier = Modifier.height(24.dp))

                                                            }
                                                        } */



                                                        Row(horizontalArrangement = Arrangement.Center, modifier = Modifier.fillMaxWidth()) {
                                                            AnimatedVisibility(createWorkspaceClicked.value){
                                                                CircularProgressIndicator()
                                                            }
                                                        }

                                                    }



                                                },
                                                confirmButton = {
                                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                                        Spacer(modifier = Modifier.weight(1f))
                                                        TextButton(onClick = {
                                                            viewModel.hideCreateWorkSpaceDialog()
                                                        }){
                                                            Text("Discard", fontSize = 15.sp, style = MaterialTheme.typography.bodyMedium,
                                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                                color = Color(0xFF5C728A))
                                                        }

                                                        Button(contentPadding = PaddingValues(horizontal = 10.dp, vertical = 5.dp),
                                                            modifier = Modifier
                                                                .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primary),onClick = {
                                                                // project in backend is workspace in frontend
                                                                val taskStatusDetails: List<TaskStatusDetailsRequest> = columns.map { response ->
                                                                    TaskStatusDetailsRequest(
                                                                        name = response.name,
                                                                        value = response.value,
                                                                        order = response.order,
                                                                        considerCompleted = if (columns.lastIndexOf(response) == columns.lastIndex) true else false
                                                                    )
                                                                }
                                                                if (homeState.editWorkSpace){
                                                                    viewModel.updateProject(projectId = homeState.selectedWorkspaceId?:"",projectName = workspaceName.value,boardId=homeState.currentBoardId?:"", description = "", taskStatusDetails = columns){
                                                                        createWorkspaceClicked.value = false
                                                                        viewModel.hideCreateWorkSpaceDialog()
                                                                        homeState.tasks.clear()
                                                                        viewModel.getWorkspaceTasks()
                                                                    }
                                                                } else {
                                                                    viewModel.createProject(projectName = workspaceName.value,boardId=homeState.currentBoardId?:"", description = "", taskStatusDetails = taskStatusDetails){
                                                                        createWorkspaceClicked.value = false
                                                                        viewModel.hideCreateWorkSpaceDialog()
                                                                    }
                                                                }

                                                                createWorkspaceClicked.value = true
                                                            }){
                                                            Text(if (homeState.editWorkSpace) "Update" else "Create", fontSize = 15.sp, style = MaterialTheme.typography.bodyMedium,
                                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                                        }



                                                    }
                                                }
                                            )
                                        }
                                        if (createBoard){
                                            val boardName = remember { mutableStateOf("") }
                                            val createBoardClicked = remember { mutableStateOf(false) }
                                            AlertDialog(
                                                onDismissRequest = { viewModel.hideCreateBoardDialog() },
                                                properties = DialogProperties(
                                                    dismissOnClickOutside = true,
                                                    dismissOnBackPress = true,
                                                    usePlatformDefaultWidth = false

                                                ),
                                                modifier = Modifier.width(415.dp).padding(vertical = 80.dp),
                                                containerColor = Color(0xFFFAFAFA),
                                                title = { Row {
                                                    Spacer(modifier = Modifier.weight(1f))
                                                    Image(painterResource(Res.drawable.x_cross),contentDescription = null,modifier=Modifier.clickable { viewModel.hideCreateBoardDialog() })
                                                }
                                                },
                                                text = {


                                                        Column(modifier = Modifier.fillMaxHeight(), verticalArrangement = Arrangement.Top, horizontalAlignment = Alignment.Start) {
                                                            Text("Organization",
                                                                fontSize = 24.sp,
                                                                style =  MaterialTheme.typography.bodyMedium, fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,color = Color(0xFF03264A))
                                                            Spacer(modifier = Modifier.height(24.dp))
                                                            ZiFocusTextField(boardName,
                                                                textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A)),
                                                                placeHolder = "Organization name"
                                                            )

                                                            Row(horizontalArrangement = Arrangement.Center,modifier = Modifier.fillMaxWidth()) {
                                                                AnimatedVisibility(createBoardClicked.value){
                                                                    CircularProgressIndicator()
                                                                }
                                                            }

                                                        }




                                                },
                                                confirmButton = {
                                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                                        Spacer(modifier = Modifier.weight(1f))
                                                        TextButton(onClick = {
                                                            viewModel.hideCreateBoardDialog()
                                                        }){
                                                            Text("Discard", fontSize = 15.sp, style = MaterialTheme.typography.bodyMedium,
                                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily,
                                                                color = Color(0xFF5C728A))
                                                        }

                                                        Button(contentPadding = PaddingValues(horizontal = 10.dp, vertical = 5.dp),
                                                            modifier = Modifier
                                                                .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),shape = RoundedCornerShape(4.dp),colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primary),onClick = {
                                                                // project in backend is workspace in frontend
                                                                viewModel.createBoard(name = boardName.value){
                                                                    createBoardClicked.value = false
                                                                    viewModel.hideCreateBoardDialog()
                                                                }
                                                                createBoardClicked.value = true
                                                            }){
                                                            Text("Create", fontSize = 15.sp, style = MaterialTheme.typography.bodyMedium,
                                                                fontFamily = MaterialTheme.typography.bodyMedium.fontFamily)
                                                        }



                                                    }
                                                }
                                            )
                                        }

                                        // alert dialog
                                        if (homeState.errorMessage!=null) {

                                            AlertDialog(
                                                onDismissRequest = {  },
                                                properties = DialogProperties(
                                                    dismissOnClickOutside = false,
                                                    dismissOnBackPress = true
                                                ),
                                                title = {  },
                                                text = { Text(homeState.errorMessage?:"Error") },
                                                confirmButton = {
                                                    Button(
                                                        modifier = Modifier.fillMaxWidth(),
                                                        onClick = {
                                                            viewModel.clearUIState()

                                                        }
                                                    ) {
                                                        Text("Dismiss")
                                                    }
                                                }
                                            )
                                        }
                                        // snackbar
                                        if (homeState.responseMessage!=null){
                                            val snackState = remember { SnackbarHostState() }
                                            val coroutineScope = rememberCoroutineScope()
                                            Box( modifier = Modifier
                                                .fillMaxSize()
                                                .padding(20.dp)) {
                                                SnackbarHost(
                                                    modifier=Modifier.align(Alignment.BottomCenter),
                                                    hostState = snackState
                                                ) { snackbarData: SnackbarData ->
                                                    CustomSnackBar(
                                                        snackbarData.visuals.message,
                                                        containerColor = Color.White
                                                    )
                                                }
                                            }
                                            coroutineScope.launch {
                                                snackState.showSnackbar(homeState.responseMessage?:"", duration = SnackbarDuration.Short)
                                                viewModel.clearSnackbar()
                                            }
                                        }




                                    }


                                }
                            }

                        })



                }
                else {
                    Scaffold(
                        content = { innerPadding ->
                            Box(
                                modifier = Modifier
                                    .padding(innerPadding)
                            ) {
                                CurrentScreen()
                            }
                        },
                        floatingActionButtonPosition = FabPosition.Center,
                        floatingActionButton = {
                            FloatingActionButton(
                                modifier = Modifier
                                    .offset(y = 60.dp)
                                    .size(42.dp),
                                containerColor = MaterialTheme.colorScheme.primary,
                                onClick = {
                                    tabNavigator.current = CreateTaskTab
                                },
                                elevation = FloatingActionButtonDefaults.elevation(
                                    defaultElevation = 0.dp
                                ),
                                shape = CircleShape
                            ) {
                                Icon(
                                    imageVector = Icons.Filled.Add,
                                    contentDescription = "",
                                    tint = MaterialTheme.colorScheme.onPrimary,
                                    modifier = Modifier.size(24.dp)
                                )
                            }
                        },
                        bottomBar = {
                            BottomNavigation(
                                backgroundColor = Color.White
                            ) {
                                TabNavigationItem(HomeTab(
                                    navigateWithRouterCardDetails, navigateBackFromCardDetails,
                                    taskId = taskId, user?.name?:""
                                ),controlProject)
                                TabNavigationItem(TaskTab(controlProject), controlProject)
                                TabNavigationItem(GraphTab, controlProject)
                                TabNavigationItem(CalenderTab, controlProject)

                            }
                        }
                    )
                }
            }

        }



    }

}
@Composable
private fun RowScope.TabNavigationItem(tab: Tab, controlProject: SnapshotStateList<String>) {
    val tabNavigator = LocalTabNavigator.current
    val isSelected = tabNavigator.current == tab

    BottomNavigationItem(
        modifier = Modifier.offset(
            x = when (tab.options.index) {
                (0u).toUShort() -> 0.dp
                (1u).toUShort() -> (-24).dp
                (2u).toUShort() -> 24.dp
                (3u).toUShort() -> 0.dp
                else -> 0.dp
            }
        ),
        selected = tabNavigator.current == tab,
        onClick = { tabNavigator.current = tab },
        icon = {
            tab.options.icon?.let {
                Icon(
                    painter = it,
                    contentDescription = tab.options.title,
                    tint = if (isSelected) Color(0xFF3D8BFD) else Color.Gray
                )
            }
        },
        label = {
            Text( when(tab.options.title){
                "Task"->{controlProject.firstOrNull()?:"Task"}
                else-> tab.options.title
            }, fontSize = 10.sp,
               // fontFamily = fontFamilyResource(MR.fonts.sf.medium),
                style =  MaterialTheme.typography.bodyMedium,
                color = if (isSelected) Color(0xFF3D8BFD) else Color.Gray)
        }
    )
}
