/**
 *
 * Please note:
 * This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit this file manually.
 *
 */

@file:Suppress(
    "ArrayInDataClass",
    "EnumEntryName",
    "RemoveRedundantQualifierName",
    "UnusedImport"
)

package org.openapitools.client.models

import org.openapitools.client.models.InfiniteBoardDataType

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

/**
 * 
 *
 * @param title 
 * @param html 
 * @param `data` 
 * @param tableData 
 */
@Serializable

data class InfiniteBoardRequest (

    @SerialName(value = "title") val title: kotlin.String? = null,

    @SerialName(value = "html") val html: kotlin.String? = null,

    @SerialName(value = "data") val `data`: kotlin.collections.List<InfiniteBoardDataType>? = null,

    @SerialName(value = "tableData") val tableData: kotlin.collections.List<kotlin.collections.List<kotlin.String>>? = null

)

