package org.company.app.presentation.ui


import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.DialogProperties
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import multiplatform_app.composeapp.generated.resources.Res
import multiplatform_app.composeapp.generated.resources.google_
import multiplatform_app.composeapp.generated.resources.signin_google_
import multiplatform_app.composeapp.generated.resources.zifocus_logo
import org.company.app.platformName
import org.company.app.presentation.component.ZiFocusPasswordTextField
import org.company.app.presentation.component.ZiFocusTextField
import org.company.app.presentation.ui.onBoarding.OtpVerificationScreen
import org.company.app.presentation.viewmodel.HomeViewModel
import org.company.app.presentation.viewmodel.LoginViewModel
import org.company.app.presentation.viewmodel.RegisterViewModel
import org.company.app.theme.AppTheme
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.koin.compose.koinInject
import org.openapitools.client.models.UserRegistration
import org.openapitools.client.models.UserRegistrationResponse

class LoginScreen(val nextWithRouter: () -> Unit={},val registerWithRouter: () -> Unit={}) : Screen {
    @Composable
    override fun Content() {

        LoginScreenContent(nextWithRouter,registerWithRouter)
    }

}

@OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun LoginScreenContent(navigateWithRouter: () -> Unit, registerWithRouter: () -> Unit) {
    AppTheme {

        val email = remember { mutableStateOf("") }
        val password = remember { mutableStateOf("") }
        val emailError = remember { mutableStateOf("invalid email") }
        var isEmailValid by remember { mutableStateOf(true) }
        var isPasswordValid by remember { mutableStateOf(true) }



        var loginClicked by remember { mutableStateOf(false) }
        val viewModel = koinInject<LoginViewModel>()
        val loginState by viewModel.loginState.collectAsState()
        val navigator = LocalNavigator.currentOrThrow

        // Define the state variable for the signin button
        val signInEnabled = remember { derivedStateOf { isEmailValid && isPasswordValid && email.value!=""&&password.value.length>=8 } }


        // Responsive Handling
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        var deviceWidth by remember { mutableStateOf(0.dp) }
        val isMobile by remember { derivedStateOf { deviceWidth < 600.dp } }
        val isTablet by remember { derivedStateOf { deviceWidth > 600.dp && deviceWidth < 1100.dp } }
        val isDesktop by remember { derivedStateOf { deviceWidth > 1100.dp } }


        val settings = Settings()
        val homeViewModel = koinInject<HomeViewModel>()
        if (settings["access_token", ""] !=""){
            // until otp api is fixed
            if (settings["isEmailVerified",false]){
                navigator.push(HomeScreen())
            }
        }

        if (loginState.loginResponse != UserRegistrationResponse()){

            homeViewModel.saveUser(loginState.loginResponse.data?: UserRegistration())
            // until otp api is fixed

            if (loginState.loginResponse.data?.isEmailVerified==true){
                if (platformName()=="Web"){
                    navigateWithRouter()
                    homeViewModel.loadImages()
                } else {
                    navigator.push(HomeScreen())
                }
            } else {
                val registerViewModel = koinInject<RegisterViewModel>()
                registerViewModel.loadImages()
                registerViewModel.resendOtp(loginState.loginResponse.data?.email?:"")
                navigator.push(OtpVerificationScreen(loginState.loginResponse.data?.email?:""))
            }
            /*
            if (platformName()=="Web"){
                navigateWithRouter()
            } else {
                    navigator.push(HomeScreen())
            }
            */

            viewModel.clearLoginUIState()
        }

        if (loginState.errorMessage!=null){
            viewModel.setOpenDialog(true)
        }

        if (loginState.isError) {
            AlertDialog(
                onDismissRequest = {  },
                properties = DialogProperties(
                    dismissOnClickOutside = false,
                    dismissOnBackPress = true
                ),
                title = { Text(text = "Login Failed") },
                text = {
                    Text(loginState.errorMessage?:"Error")
                    emailError.value = loginState.errorMessage?.split("_")?.joinToString(" ")?:"invalid email"
                    isEmailValid= false
                },
                confirmButton = {
                    Button(
                        modifier = Modifier.fillMaxWidth(),
                        onClick = {
                            viewModel.clearLoginUIState()
                            loginClicked = false
                        }
                    ) {
                        Text("Dismiss")
                    }
                }
            )
        }

        BoxWithConstraints(modifier = Modifier.fillMaxSize().statusBarsPadding()
            .navigationBarsPadding()) {
            LaunchedEffect(this.maxWidth) {
                deviceWidth = this@BoxWithConstraints.maxWidth
            }
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
                modifier = Modifier.fillMaxSize().windowInsetsPadding(WindowInsets.safeDrawing).padding(top = 30.dp)
            ) {
                //  if (!useNavRail) Spacer(modifier = Modifier.height(56.dp))

                if (!useNavRail) Image(painterResource(Res.drawable.zifocus_logo),contentDescription = "logo")
                Row(modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f)) {
                    Text(
                        text = buildAnnotatedString {
                            withStyle(
                                SpanStyle(
                                    fontSize = if (useNavRail )32.sp else 20.sp,
                                    fontStyle = if (useNavRail )MaterialTheme.typography.bodyLarge.fontStyle else MaterialTheme.typography.displayMedium.fontStyle,
                                    fontFamily = if (useNavRail )MaterialTheme.typography.bodyLarge.fontFamily else MaterialTheme.typography.displayMedium.fontFamily,
                                    // fontFamily = if (useNavRail ) FontFamily(Font(Res.font.sf_bold)) else MaterialTheme.typography.displayMedium.fontFamily,
                                    fontWeight = FontWeight.Bold,
                                    color = Color(0xFF03264A)
                                )
                            ) {
                                append(if (useNavRail)"Welcome to ZiFocus!" else "Welcome")
                            }
                            append("\nPlease sign in to continue")
                        },
                        modifier = Modifier.padding(8.dp),
                        fontSize = if (useNavRail) 17.sp else 15.sp,
                        style = MaterialTheme.typography.bodySmall,
                        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                        lineHeight = 40.sp,
                        color = Color(0xFF5C728A),
                        textAlign = TextAlign.Start

                    )
                }
                Spacer(modifier = Modifier.height(12.dp))

                ZiFocusTextField(
                    value = email.value,
                    onValueChange = {
                        email.value = it
                        isEmailValid = isValidEmail(it)
                    },
                    placeHolder = "Type Email",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp),
                    isError = !isEmailValid,
                    supportingText = emailError.value

                )
                Spacer(modifier = Modifier.height(32.dp))

                ZiFocusPasswordTextField(
                    value = password.value,
                    onValueChange = {
                        password.value = it
                        isPasswordValid = it.length>=8
                        isEmailValid = isValidEmail(email.value)
                    },
                    placeHolder = "Type Password",
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(horizontal = 8.dp),
                    isError = !isPasswordValid,
                    supportingText = "invalid password"
                )

                /*
                OutlinedTextField(
                    value = password.value,
                    onValueChange = {
                        password.value = it
                        passwordError = password.value.length < 8
                    },
                    label = { Text("Type Password") },
                    isError = passwordError,
                    textStyle = TextStyle(fontSize = 15.sp, fontFamily = fontFamilyResource(MR.fonts.sf.regular)),
                    supportingText = { if (passwordError) Text("Must be at least 8 characters") },
                    singleLine = true,
                    shape = RoundedCornerShape(10.dp),
                    visualTransformation = if (passwordVisibility) VisualTransformation.None else PasswordVisualTransformation(),
                    modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f).padding(8.dp),
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Password
                    ),
                    trailingIcon = {
                        IconButton(onClick = { passwordVisibility = !passwordVisibility }) {
                            val imageVector = if (passwordVisibility) painterResource(MR.images.hide_password)  else painterResource(MR.images.eye_password)
                            Icon(
                                imageVector,
                                contentDescription = if (passwordVisibility) "Hide password" else "Show password"
                            )
                        }
                    }
                )
                */



                Row(modifier = Modifier.fillMaxWidth(if (useNavRail)0.5f else 1f)) {
                    Spacer(modifier = Modifier.weight(1f))
                    TextButton(contentPadding = PaddingValues(horizontal = 5.dp, vertical = 5.dp),
                        modifier = Modifier
                            .defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
                        ,onClick = {navigator.push(ForgotPasswordScreen)}){
                        Text(text = "Forgot password?", fontSize = 15.sp,
                            //  fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                            style = MaterialTheme.typography.bodySmall,
                            color = MaterialTheme.colorScheme.primaryContainer,
                            //  modifier = Modifier.padding(end = 16.dp)
                        )
                    }

                }
                Button(
                    onClick = {
                        viewModel.login(email.value, password.value)
                        loginClicked = true
                    },
                    modifier = Modifier.padding(16.dp).width(213.dp),
                    shape = RoundedCornerShape(10.dp),
                    enabled = signInEnabled.value
                ) {
                    Text("Sign In", fontSize = 17.sp,
                        //  fontFamily = fontFamilyResource(MR.fonts.sf.semibold)
                        color = Color(0xFFFAFAFA),
                        fontFamily = MaterialTheme.typography.displayMedium.fontFamily,
                        style = MaterialTheme.typography.displayMedium,
                    )
                }
                AnimatedVisibility(loginClicked){
                    CircularProgressIndicator()
                }
                Spacer(modifier = Modifier.height(24.dp))
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                    HorizontalDivider(modifier = Modifier.width(130.dp))
                    Text("or", fontSize = 15.sp,
                        style = MaterialTheme.typography.bodySmall, modifier = Modifier.padding(bottom = 8.dp).padding(horizontal = 4.dp))
                    HorizontalDivider(modifier = Modifier.width(130.dp))
                }
                Spacer(modifier = Modifier.height(24.dp))

                OutlinedButton(onClick = {},shape = RoundedCornerShape(10.dp)){
                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                        Image(painterResource(Res.drawable.google_),contentDescription = null,
                            modifier = Modifier.size(24.dp).padding(end = 4.dp))



                        Text("Sign in using google", color = Color.Black,fontSize = 15.sp, style = MaterialTheme.typography.bodySmall)
                    }
                }

                Spacer(modifier = Modifier.height(12.dp))
                Text(
                    text = buildAnnotatedString {
                        append("Not a registered member? ")
                        withStyle(
                            SpanStyle(
                                //  fontFamily = fontFamilyResource(MR.fonts.sf.semibold),
                                fontStyle = MaterialTheme.typography.displayMedium.fontStyle,
                                fontFamily = MaterialTheme.typography.displayMedium.fontFamily,
                                color = MaterialTheme.colorScheme.primaryContainer
                            )
                        ) {
                            append("Sign Up")
                        }
                    },
                    color = Color(0xFF03264A).copy(alpha = 0.8f),
                    //  fontFamily = fontFamilyResource(MR.fonts.sf.regular),
                    style = MaterialTheme.typography.bodySmall,
                    fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
                    fontSize = 15.sp,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.fillMaxWidth().padding(16.dp).clickable(onClick = {
                        if (platformName()=="Web") registerWithRouter()
                        else navigator.push(RegisterScreen())

                    }, indication = null, interactionSource = MutableInteractionSource())
                )
            }
        }




        // App Version Number
        Box(modifier = Modifier.fillMaxSize()) {
            Text("v1.0.3", textAlign = TextAlign.Center, modifier = Modifier.align(Alignment.BottomCenter).padding(bottom = 20.dp),
                //  fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                style = MaterialTheme.typography.bodySmall
            )
        }


    }
}

fun isValidEmail(email: String): Boolean {
    val regex = Regex("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")
    return regex.matches(email)
}
