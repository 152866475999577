package org.company.app.presentation.component

import Route
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mohamedrejeb.richeditor.model.RichTextState
import kotlinx.serialization.json.Json
import org.company.app.presentation.component.table.ContentCell
import org.company.app.presentation.component.table.HeaderCell
import org.company.app.presentation.component.table.Table
import org.company.app.presentation.component.table.ToggleCell
import org.company.app.presentation.ui.TextFieldState
import org.company.app.presentation.ui.carddetails.AutoSubmittingRicthTextEditor
import org.company.app.presentation.ui.carddetails.AutoSubmittingTextField
import org.company.app.presentation.utils.dateTimeToString
import org.company.app.presentation.utils.prettyFormat
import org.company.app.presentation.utils.selectedDateMillisToLocalDateTime

@OptIn(ExperimentalComposeUiApi::class, ExperimentalMaterial3Api::class)
@Composable
fun LoopComponent(modifier: Modifier = Modifier, basicRichTextState: RichTextState, textField: TextFieldState,onClick: ()->Unit={},createTable: (List<MutableList<String>>)->Unit={}){
    val isDropdownMenuExpanded = remember { mutableStateOf(false) }
    val divider = remember { mutableStateOf(false) }
    val isTable = remember { mutableStateOf(false) }
    val isWhiteBoard = remember { mutableStateOf(false) }
    var datePicker = remember { mutableStateOf(false) }
    var dateSelected =rememberDatePickerState(yearRange = 2023..2050,)
    Column(modifier) {
        Box() {
            if (basicRichTextState.annotatedString.text==""){
                Text("Type Description", fontSize = 18.sp, style = MaterialTheme.typography.bodySmall, fontWeight = FontWeight.Light,color = Color.Gray,modifier = Modifier.padding(start = 16.dp))
            }
            AutoSubmittingRicthTextEditor(basicRichTextState, onClick = {
                onClick()
            }, textStyle = MaterialTheme.typography.bodySmall.copy(fontSize = 18.sp, color = Color(0xFF5C728A), fontWeight = FontWeight.Light),
                modifier = Modifier.onKeyEvent {
                    if (it.key == Key.Slash) {
                        // Perform action when "/" is pressed
                        isDropdownMenuExpanded.value=true
                        true
                    } else {
                        false
                    }
                }
            )

            DropdownMenu(
                expanded = isDropdownMenuExpanded.value,
                onDismissRequest = { isDropdownMenuExpanded.value = false },
                modifier = Modifier.align(Alignment.TopEnd).background(color = Color.White)
            ){
                Text("General", fontSize = 11.sp, style =  MaterialTheme.typography.bodySmall,
                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                    ,modifier = Modifier.padding(start = 6.dp))


                DropdownMenuItem(
                    text = { Text("Table",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        isTable.value = true
                        textField.type = "table"
                    }
                )
                DropdownMenuItem(
                    text = { Text("Voting Table",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        isTable.value = true
                        textField.type = "voting_table"
                    }
                )
                DropdownMenuItem(
                    text = { Text("WhiteBoard",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        isWhiteBoard.value = true
                        textField.type = "WhiteBoard"
                    }
                )

                DropdownMenuItem(
                    text = { Text("Bullet list",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/",""))
                        basicRichTextState.toggleUnorderedList()
                        textField.type = "bullet_list"
                    }
                )
                DropdownMenuItem(
                    text = { Text("Numbered list",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.setText(basicRichTextState.annotatedString.text.replace("/",""))
                        basicRichTextState.toggleOrderedList()
                        textField.type = "numbered_list"
                    }
                )
                DropdownMenuItem(
                    text = { Text("Divider",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        divider.value = !divider.value
                    }
                )

                HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(vertical = 6.dp))
                Text("Text styles", fontSize = 11.sp, style =  MaterialTheme.typography.bodySmall,
                    fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                    ,modifier = Modifier.padding(start = 6.dp))

                DropdownMenuItem(
                    text = { Text("Heading 1",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.toggleSpanStyle(
                            SpanStyle(
                                fontWeight = FontWeight.ExtraBold,
                                fontSize = 28.sp
                            )
                        )
                    }
                )

                DropdownMenuItem(
                    text = { Text("Heading 2",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.toggleSpanStyle(
                            SpanStyle(
                                fontWeight = FontWeight.Bold,
                                fontSize = 20.sp,
                            )
                        )
                    }
                )

                DropdownMenuItem(
                    text = { Text("Heading 3",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall,
                        fontFamily =  MaterialTheme.typography.bodySmall.fontFamily
                        , fontSize = 13.sp) },
                    onClick = {
                        isDropdownMenuExpanded.value = false
                        basicRichTextState.toggleSpanStyle(
                            SpanStyle(
                                fontWeight = FontWeight.SemiBold,
                                fontSize = 14.sp
                            )
                        )
                    }
                )



            }

        }
        if (datePicker.value){
            DatePickerDialog(onDismissRequest = {datePicker.value=false}, confirmButton = {
                TextButton(onClick = {
                    datePicker.value = false
                }) {
                    Text("Confirm",
                        // fontFamily = fontFamilyResource(MR.fonts.sf.regular)
                        style =  MaterialTheme.typography.bodySmall
                        , fontSize = 15.sp, color = MaterialTheme.colorScheme.primaryContainer)
                }
            }, colors = DatePickerDefaults.colors(containerColor = Color(0xFFFAFAFA))){
                DatePicker(
                    state = dateSelected,
                    modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                    colors = DatePickerDefaults.colors(selectedDayContainerColor = MaterialTheme.colorScheme.primaryContainer, todayDateBorderColor = MaterialTheme.colorScheme.primaryContainer, todayContentColor = MaterialTheme.colorScheme.primaryContainer)
                )
            }
        }
        if (isTable.value) {

            val checkedRows = remember {
                mutableStateMapOf<Int, Unit>()
            }

            // table
        Column(horizontalAlignment = Alignment.CenterHorizontally) {

        val data = when(textField.type){
            "voting_table"-> {
                remember { mutableStateListOf(
                    mutableStateListOf("","#","Name", "Age", "Country","Voting","Date"),
                    mutableStateListOf("","","", "", "","",""),
                    ) }
            }
            else -> {
                remember { mutableStateListOf(
                    mutableStateListOf("","#","Name", "Age", "Country","Date"),
                    mutableStateListOf("","","", "", "",""),

                    ) }
            }
        }
        Row {
        Button(onClick={
            val newRow = List(data.firstOrNull()?.size?:0) { "" }.toMutableStateList()
            data.add(newRow)
        }){
            Text("+ Row")
        }

        Button(onClick={
            data.forEachIndexed { index, strings ->
                strings.add(if (index==0) "New Column" else "")
            }
        }){
            Text("+ Column")
        }
    }
        Table(
        modifier = Modifier
            .fillMaxSize()
            .padding(10.dp),
        columnCount = data.firstOrNull()?.size?:0,
        rowCount = data.size ,
        stickyRowCount = 0,
        stickyColumnCount = 0,
        maxCellWidthDp = 320.dp,
       // customHeaders = listOf("Voting","Date"),
        customHeaders = listOf("Voting","Date"),
        customElements = listOf(
            {   var counter = mutableStateOf(0)
                Button(onClick = {
                    counter.value++
                }){
                    Text(counter.value.toString())
                }
            },
            {
                TextButton(onClick = {
                    datePicker.value=true
                }){ Text(
                    if (dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().date.prettyFormat().contains("1970"))dateSelected.selectedDateMillis.selectedDateMillisToLocalDateTime().date.prettyFormat()
                    else "Date"
                ) }
            }
        )
    ) { rowIndex, columnIndex ,customHeader , customElement->

        val header = data.firstOrNull()?.get(columnIndex)

        if (rowIndex == 0) {
            HeaderCell(header?:"")
        } else {
            when (header) {
                "" -> {
                    ToggleCell(rowIndex - 1,checkedRows)
                }
                "#" -> {
                    ContentCell(rowIndex.toString(), checkedRows.containsKey(rowIndex - 1))
                }
                // this voting i want it to be parameter in the table
               in customHeader->{
                   val customIndex = customHeader.indexOf(header)
                    Box(modifier = Modifier
                        .background(
                            color = Color.White,
                            shape = RoundedCornerShape(corner = CornerSize(0.dp))
                        )
                        .border(width = 1.dp, color = Color.Gray)
                        .clickable {
                            // do something wonderful
                        }
                    ) {
                            customElement[customIndex]()
                    }

                }
                else -> {
                    val r = data[rowIndex]
                    ContentCell(remember {  mutableStateOf(r[columnIndex]) }, checkedRows.containsKey(rowIndex - 1)){
                        data[rowIndex][columnIndex] = it?:""
                        createTable(data)
                        println(data.toString())


                    }
                }
            }


        }

    }


}
    }
        if (isWhiteBoard.value){

        }

    if (divider.value){
        HorizontalDivider(modifier = Modifier.fillMaxWidth().padding(top = 6.dp))
    }
}

}