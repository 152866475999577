package org.company.app.presentation.component.board.card

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.material.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

import org.company.app.data.model.CardModel
import org.jetbrains.compose.resources.ExperimentalResourceApi

@OptIn(ExperimentalResourceApi::class)
@Composable
fun TaskCard(
    modifier: Modifier = Modifier,
    onClick: () -> Unit,
    cardModel: CardModel,
    editModeEnabled: Boolean = false,
    saveClicked: Boolean,
    onEditDone: (Int, Int, String) -> Unit,
    isExpandedScreen: Boolean = false
) {
    val editedTitle = remember { mutableStateOf(TextFieldValue()) }

    LaunchedEffect(saveClicked, editModeEnabled) {
        if (saveClicked && editModeEnabled) {
            cardModel.listId?.let { safeListId ->
                onEditDone(
                    cardModel.id,
                    safeListId,
                    editedTitle.value.text
                )
            }
        }
    }

    Card(
        modifier = modifier
            .padding(4.dp)
            .fillMaxWidth()
            .height(116.dp)
            .clickable { onClick() },
        shape = RoundedCornerShape(4.dp),
        elevation = 4.dp,
        backgroundColor = MaterialTheme.colorScheme.surface
    ) {
        Column(
            modifier = Modifier
        ) {

            Column(
                modifier = Modifier.padding(8.dp)
            ) {
                cardModel.labels.let { cardLabels ->
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(4.dp)
                    ) {
                        for (label in cardLabels) {
                            Box(
                                modifier = Modifier
                                    .width(24.dp)
                                    .height(4.dp)
                                    .clip(RoundedCornerShape(10))
                                    .background(color = Color(label.labelColor))
                            )
                        }
                    }
                }

                Spacer(modifier = Modifier.height(8.dp))

                if (editModeEnabled) {
                    TextField(
                        modifier = Modifier,
                        colors = TextFieldDefaults.textFieldColors(
                            cursorColor = MaterialTheme.colorScheme.secondary,
                            focusedIndicatorColor = MaterialTheme.colorScheme.secondary
                        ),
                        placeholder = {
                            Text(
                                modifier = Modifier,
                                text = "Card name",
                                style = TextStyle(
                                    fontSize = 14.sp,
                                    fontWeight = FontWeight.SemiBold
                                )
                            )
                        },
                        value = editedTitle.value,
                        onValueChange = { editedTitle.value = it }
                    )
                } else {
                    Text(
                        modifier = Modifier,
                        text = cardModel.title,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        style = TextStyle(
                            fontSize = 14.sp
                        )
                    )
                    Spacer(modifier = Modifier.height(8.dp))

                    cardModel.description?.let { safeDescription ->
                        if (safeDescription.isNotEmpty() && isExpandedScreen) {
                            Text(
                                modifier = Modifier,
                                text = safeDescription,
                                maxLines = 2,
                                overflow = TextOverflow.Ellipsis,
                                style = TextStyle(
                                    fontSize = 12.sp
                                )
                            )
                        }
                    }

                    Spacer(modifier = Modifier.height(2.dp))

                }
            }
        }
    }
}


@Composable
fun RowScope.TableCell(
    text: String,
    weight: Float
) {
    Text(
        text = text,
        fontSize = 18.sp,
        fontFamily = MaterialTheme.typography.bodySmall.fontFamily,
        style = MaterialTheme.typography.bodySmall,
        color = Color(0xFF5C728A).copy(alpha = 0.6f)
        , textAlign = TextAlign.Start,

        modifier =  Modifier
            .weight(weight)
            .padding(vertical = 8.dp),
    )
}
