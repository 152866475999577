package org.company.app.presentation.viewmodel

import androidx.compose.runtime.MutableState
import androidx.compose.ui.graphics.ImageBitmap
import api.*
import com.darkrockstudios.libraries.mpfilepicker.MPFile
import com.mohamedrejeb.calf.io.KmpFile
import com.mohamedrejeb.calf.io.getName
import com.mohamedrejeb.calf.io.readByteArray
import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import dev.icerock.moko.mvvm.viewmodel.ViewModel
import io.github.aakira.napier.Napier
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import org.company.app.data.model.*
import org.company.app.util.ApiResponse
import org.company.app.util.Constants
import org.company.app.util.safeRequest
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.readResourceBytes
import org.openapitools.client.models.*
import org.openapitools.client.models.Board
import org.openapitools.client.models.Comment
import org.openapitools.client.models.CreateTaskRequest
import toImageBitmap

 data class HomeUiState(
     val getMyBoardResponse: BoardListResponse = BoardListResponse(),
     val infiniteBoardResponse: InfiniteBoardResponse = InfiniteBoardResponse(),
     val infiniteListBoardResponse: InfiniteListBoardResponse= InfiniteListBoardResponse(),
     val boards: MutableList<Board?> = mutableListOf(),
     val boardMembers: MutableList<BoardMember?> = mutableListOf(),
     var categories: MutableList<CategoryItem> = mutableListOf(
         CategoryItem(title = "Work", color = 0xFFA98EDA),
         CategoryItem(title = "Design", color =0xFFFEB272),
         CategoryItem(title = "Sales", color =0xFFFFDA6A),
         CategoryItem(title = "Backend", color =0xFF6EA8FE),
         CategoryItem(title = "QA", color =0xFFEA868F),
    ),
     val features: MutableList<FeatureData> = mutableListOf(),
     var feature: FeatureData? = null,
     val errorMessage: String? =null,
     var isError: Boolean = false,
     var deleteBoard: String?=null,
     var createProject:String? = null,
     var selectedWorkspace:String? = null,
     var selectedProject:Project?=null,
     var selectedWorkspaceId:String? = null,
     var currentProject:String? = null,
     var currentFeature:String? = null,
     var currentBoardId:String? = null,
     var currentBoardName:String? = null,
     var responseMessage:String? = null,
     var attachment:String? = null,
     val projects: MutableList<Project?> = mutableListOf(),
     var task: TaskItem? = null,
     var comment: Comment? = null,
     var blocker: TaskItem? = null,
     val tasks: MutableList<TaskItem?> = mutableListOf(),
     val subTasks: MutableList<TaskItem> = mutableListOf(),
     var user: UserRegistration? = null,
     var loadedTasks: Boolean = false,
     var loadedProject: Boolean = false,
     var subTaskCreated: Boolean = false,
     var navigateToHome: Boolean = false,
     var createWorkspace: Boolean = false,
     var editWorkSpace: Boolean = false,
     var createBoard: Boolean = false,
     var acceptedBoardInvite: String? = null,
     var nextPageToken: String? = null,
     var myNotifications:  List<NotificationItems>? = listOf(),
     var boardMember: BoardMember? = BoardMember(),

     )

data class ImagesUiState(

    val time_image :ImageBitmap?=null,
    val pause_image :ImageBitmap?=null,
    val play_image :ImageBitmap?=null,
    val kanban_done :ImageBitmap?=null,
    val assign_member_image :ImageBitmap?=null,
    val priority_flag_image :ImageBitmap?=null,
    val due_datepicker_image :ImageBitmap?=null,
    var imagesLoaded: Boolean = false,
)
@OptIn(ExperimentalResourceApi::class)
class HomeViewModel(val client: HttpClient, val boardsApi: BoardsApi,val featuresApi: FeaturesApi
,val toDoApi: ToDoApi, val registrationApi: RegistrationApi,val commentsApi: CommentsApi,val notificationApi: NotificationApi
): ViewModel() {
    private val _homeState = MutableStateFlow<HomeUiState>(HomeUiState())
    public val homeState: StateFlow<HomeUiState> = _homeState.asStateFlow()

    private val _imagesState = MutableStateFlow<ImagesUiState>(ImagesUiState())
    public val imagesState: StateFlow<ImagesUiState> = _imagesState.asStateFlow()

    val _isSearching = MutableStateFlow(false)
    val isSearching = _isSearching.asStateFlow()

    //second state the text typed by the user
    val _searchText = MutableStateFlow("")
    val searchText = _searchText.asStateFlow()
    val settings = Settings()

    val _boardMemberList = flow {
        emit(_homeState.value.boardMembers)
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(5000),
        initialValue = emptyList()
    )

    val boardMemberList = searchText
        .combine(_boardMemberList) { text, members ->
            if (text.isBlank()) {
                members
            }
            members.filter { member ->
                member?.name!!.uppercase().contains(text.trim().uppercase())
            }
        }.stateIn(
            scope = viewModelScope,
            started = SharingStarted.WhileSubscribed(5000),
            initialValue = _boardMemberList.value
        )





    val _categories = flow {
        emit(_homeState.value.categories)
    }.stateIn(
        scope = viewModelScope,
        started = SharingStarted.WhileSubscribed(1),
        initialValue = mutableListOf()
    )

    val categories = searchText
        .combine(_categories) { text, members ->
            if (text.isBlank()) {
                members
            }
            members.filter { member ->
                member.title!!.uppercase().contains(text.trim().uppercase())
            }
        }.stateIn(
            scope = viewModelScope,
            started = SharingStarted.WhileSubscribed(5000),
            initialValue = _categories.value.distinct()
        )


    fun clearLogout(){
        _homeState.update {
            HomeUiState()
        }
    }

    fun showCreateWorkSpaceDialog(editWorkSpace:Boolean=false){
        _homeState.update {
            it.copy(createWorkspace = true,editWorkSpace=editWorkSpace)
        }
    }
    fun hideCreateWorkSpaceDialog(){
        _homeState.update {
            it.copy(createWorkspace = false)
        }
    }

    fun showCreateBoardDialog(){
        _homeState.update {
            it.copy(createBoard = true)
        }
    }
    fun hideCreateBoardDialog(){
        _homeState.update {
            it.copy(createBoard = false)
        }
    }



    fun refreshTokenAndRetry(block: suspend () -> Unit){
        viewModelScope.launch {

            val settings: Settings = Settings()
            val response= client.safeRequest<RefreshTokenResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("https://securetoken.googleapis.com/v1/token")
                    parameters.append("key","AIzaSyAJtMviEglTcNmjrYbiadsqHh8xIA8T1GQ")
                }
                contentType(ContentType.Application.Json)
                setBody(RefreshTokenRequest(grant_type = "refresh_token",refresh_token = settings["refresh_token"]))


            }
            when(response){
                is ApiResponse.Success -> {
                    Constants.token = response.body.idToken?:""
                    settings["access_token"] = response.body.idToken
                    settings["refresh_token"] = response.body.refreshToken
                    block()
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")

                    if (response.errorBody?.contains("Unauthorized") == true){
                        _homeState.update {
                            it.copy(
                                responseMessage = "Please Wait , Refreshing your Credentials")
                        }
                    } else {
                        _homeState.update {
                            it.copy(errorMessage = response.errorBody)
                        }
                    }


                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
    }
    @OptIn(InternalResourceApi::class)
    fun loadImages(){
        if (!_imagesState.value.imagesLoaded){
            viewModelScope.launch {
                try {
                    _imagesState.update {
                        it.copy(
                            time_image = readResourceBytes("drawable/time_.png").toImageBitmap(),
                            pause_image = readResourceBytes("drawable/pause.png").toImageBitmap(),
                            kanban_done = readResourceBytes("drawable/kanban_done_.png").toImageBitmap(),
                            play_image = readResourceBytes("drawable/play.png").toImageBitmap(),

                            assign_member_image = readResourceBytes("drawable/assign_member_.png").toImageBitmap(),
                            priority_flag_image = readResourceBytes("drawable/priority_flag_.png").toImageBitmap(),
                            due_datepicker_image = readResourceBytes("drawable/due_datepicker_.png").toImageBitmap(),



                            imagesLoaded = true
                        )
                    }
                }catch (exception:Exception){

                }

            }
        }

    }
    override fun onCleared() {
        client.close()
    }
    fun onSearchTextChange(text: String) {
        _searchText.value = text
    }

    fun onToogleSearch() {
        _isSearching.value = !_isSearching.value
        if (!_isSearching.value) {
            onSearchTextChange("")
        }
    }
    fun currentTask(task: TaskItem?){
        _homeState.update {
            it.copy(task = task)
        }
    }
    fun clearProjects(loadedProject: Boolean=false){
        _homeState.update {
            it.copy(loadedProject = loadedProject, projects = arrayListOf(), tasks = arrayListOf(), selectedWorkspaceId = null, selectedWorkspace = null)
        }
    }

    fun currentBoard(boardId: String?, name: String?,navigateToHome: Boolean=false){
        _homeState.update {
            it.copy(currentBoardId = boardId, currentBoardName = name, navigateToHome = navigateToHome)
        }
        if (boardId!=null)
        getBoardMembers(boardId)
    }


    fun clearUIState(){
        _homeState.update {
            it.copy(errorMessage = null,isError = false)
        }
    }

    fun getUser(function: (UserRegistration?) -> Unit={}) {

        viewModelScope.launch {

            val response = registrationApi.getUsers(authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(user = response.body().data)
                }
                function(response.body().data)
            }
            else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry{
                        getUser()
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }


            }



        }


    }

    fun getNotifications(response: (GetMyNotificationResponse) -> Unit={}) {
        viewModelScope.launch {
            val response = notificationApi.getMyNotification(authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(myNotifications = response.body().data)
                }
                response(response.body())
            }
            else {
                if (response.body().message=="Unauthorized"){
                    refreshTokenAndRetry{
                        getNotifications()
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }


    }

    fun readNotification(notificationId : String) {
        viewModelScope.launch {
            val response = notificationApi.markThisNotificationRead(authorization = settings["access_token"], id = notificationId)
            if (response.success){
                _homeState.update {
                    it.copy(responseMessage = response.body().message)
                }
            }
            else {
                if (response.body().message=="Unauthorized"){
                    refreshTokenAndRetry{
                        getNotifications()
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }


    }

    fun createInfiniteBoard(data:List<InfiniteBoardDataType>,tableData: List<MutableList<String>>?=null){
        viewModelScope.launch {
            val response = boardsApi.createInfiniteBoard(authorization = settings["access_token"], infiniteBoardRequest = InfiniteBoardRequest(
                title = _homeState.value.user?.id, data = data, tableData = tableData))
            if (response.success){
                _homeState.update {
                    it.copy(infiniteBoardResponse = response.body()
                    )
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }



        }

    }

    fun getInfiniteBoard(){
        viewModelScope.launch {
            val response = boardsApi.getAllInfiniteBoard(authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(infiniteListBoardResponse = response.body()
                    )
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }



        }

    }


    fun getBoards(){
        viewModelScope.launch {
            val response = boardsApi.getMyBoards(authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(getMyBoardResponse = response.body(), boards = response.body().data?.toMutableList()?: arrayListOf(),deleteBoard = null,
                        currentBoardId = response.body().data?.firstOrNull()?.id, currentBoardName = response.body().data?.firstOrNull()?.name,
                        loadedProject = if (response.body().data?.firstOrNull()?.id==null) true else false
                    )
                }
                if (_homeState.value.currentBoardId!=null){
                    getBoardProjects()
                    getBoardMembers(_homeState.value.currentBoardId?:"")
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                        getBoards()
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }



        }

    }
    fun selectWorkSpace(workSpace :Project,workSpaceId:String,navigateToHome: Boolean=false){
        _homeState.update {
            it.copy(selectedProject = workSpace,selectedWorkspace = workSpace.projectName, selectedWorkspaceId = workSpaceId, responseMessage = "Workspace ( ${workSpace.projectName} ) is selected", navigateToHome = navigateToHome)
        }
    }
    fun navigateToHome(navigateToHome: Boolean=false){
        _homeState.update {
            it.copy(navigateToHome = navigateToHome)
        }
    }

    fun deleteBoard(boardId: String,block: (MutableList<Board>) -> Unit={}){
        viewModelScope.launch {

            val response = boardsApi.deleteBoard(boardId = boardId,authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(deleteBoard = response.body().message, boards = response.body().data?.toMutableList()?: arrayListOf(), responseMessage = response.body().message)
                }
                block(response.body().data?.toMutableList()?: arrayListOf())
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                        deleteBoard(boardId)
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }
    }
    fun clearSnackbar(){
        _homeState.update {
            it.copy(deleteBoard = null, createBoard = false, responseMessage = null)
        }
    }


    fun createProject(projectName:String, description:String,startDate:String?=null,endDate:String?=null, taskStatusDetails: List<TaskStatusDetailsRequest>? = null,boardId:String,block: () -> Unit={}){
        viewModelScope.launch {


            val response =  featuresApi.createProject(ProjectRequest(projectName, description, startDate, endDate, boardId,taskStatusDetails),authorization = settings["access_token"])
            if (response.success){
                _homeState.value.projects.add(response.body().data)
                _homeState.update {
                    it.copy(createProject = response.body().message, responseMessage = response.body().message)
                }
                block()
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }


    }


    fun updateProject(projectId: String,projectName:String, description:String,startDate:String?=null,endDate:String?=null, taskStatusDetails: List<TaskStatusDetailsResponse>? = null,boardId:String,block: () -> Unit={}){
        viewModelScope.launch {


            val response =  featuresApi.updateProject(projectId = projectId,
                UpdateProjectRequest(projectName, description, startDate, endDate, boardId,taskStatusDetails),authorization = settings["access_token"])
            if (response.success){
                _homeState.value.selectedProject = response.body().data
                _homeState.value.projects.find { it?.id==response.body().data?.id }.apply {
                    this?.projectName= response.body().data?.projectName
                    this?.description= response.body().data?.description
                    this?.taskStatusDetails= response.body().data?.taskStatusDetails
                }
                _homeState.update {
                    it.copy(selectedProject = response.body().data, selectedWorkspace = response.body().data?.projectName,projects = _homeState.value.projects, responseMessage = response.body().message)
                }
                block()
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }


    }


    fun getBoardProjects(){

        viewModelScope.launch {
            val response =  featuresApi.getBoardProjects(_homeState.value.currentBoardId?:"",authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(projects = response.body().data?.toMutableList()?: arrayListOf(), loadedProject = true)
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }


    fun uploadFile(file: MPFile<Any>?,block:  (String?) -> Unit={}) {
        viewModelScope.launch {
            val fileToUpload=  file?.getFileByteArray()
            val (fileName, fileExtension) = extractFileNameAndExtension(file?.path!!)

            val response= client.safeRequest<UploadFileResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("${Constants.UPLOAD_URL}/${Constants.env}/uploadFile?userid=${_homeState.value.user?.id}")
                    setBody(MultiPartFormDataContent(
                        formData {
                            append("description", "Ktor logo")
                            append("file", fileToUpload!!, Headers.build {
                                append(HttpHeaders.ContentType, if (fileExtension.contains("pdf"))"application/pdf" else fileExtension)
                                append(HttpHeaders.ContentDisposition, "filename=\"${fileName}.$fileExtension\"")
                            })
                        }
                    )
                    )
                }

            }
            when(response){
                is ApiResponse.Success -> {
                    _homeState.value.task?.attachments?.add(response.body.filePath?:"")
                    _homeState.update {
                        it.copy(task = _homeState.value.task?.copy()?: TaskItem(), responseMessage = "File Uploaded Successfully")
                    }

                    updateTask(_homeState.value.task?.copy()?: TaskItem())
                    block(response.body.filePath)
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage =  response.errorMessage)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
    }

    fun uploadFile(file:  KmpFile?,block:  (String?) -> Unit={},context:com.mohamedrejeb.calf.core.PlatformContext) {
        viewModelScope.launch {
            val fileToUpload=  file?.readByteArray(context)
            val (fileName, fileExtension) = extractFileNameAndExtension(file?.getName(context)?:"")

            val response= client.safeRequest<UploadFileResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("${Constants.UPLOAD_URL}/${Constants.env}/uploadFile?userid=${_homeState.value.user?.id}")
                    setBody(MultiPartFormDataContent(
                        formData {
                            append("description", "Ktor logo")
                            append("file", fileToUpload!!, Headers.build {
                                append(HttpHeaders.ContentType, if (fileExtension.contains("pdf"))"application/pdf" else fileExtension)
                                append(HttpHeaders.ContentDisposition, "filename=\"${fileName}.$fileExtension\"")
                            })
                        }
                    )
                    )
                }

            }
            when(response){
                is ApiResponse.Success -> {
                    _homeState.value.task?.attachments?.add(response.body.filePath?:"")
                    _homeState.update {
                        it.copy(task = _homeState.value.task?.copy()?: TaskItem(), responseMessage = "File Uploaded Successfully")
                    }

                    updateTask(_homeState.value.task?.copy()?: TaskItem())
                    block(response.body.filePath)
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage =  response.errorMessage)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
    }

    fun uploadProfilePhoto(file: KmpFile?,block:  (String?) -> Unit,context:com.mohamedrejeb.calf.core.PlatformContext) {
        viewModelScope.launch {
            val fileToUpload=  file?.readByteArray(context)
            val (fileName, fileExtension) = extractFileNameAndExtension(file?.getName(context)?:"")

            val response= client.safeRequest<UploadFileResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("${Constants.UPLOAD_URL}/${Constants.env}/uploadFile?userid=${_homeState.value.user?.id}")
                    setBody(MultiPartFormDataContent(
                        formData {
                            append("description", "Ktor logo")
                            append("file", fileToUpload!!, Headers.build {
                                append(HttpHeaders.ContentType, if (fileExtension.contains("pdf"))"application/pdf" else fileExtension)
                                append(HttpHeaders.ContentDisposition, "filename=\"${fileName}.$fileExtension\"")
                            })
                        }
                    )
                    )
                }

            }
            when(response){
                is ApiResponse.Success -> {
                    _homeState.update {
                        it.copy(responseMessage = "Image Uploaded Successfully")
                    }
                    block(response.body.filePath)
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage =  response.errorMessage)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
    }

    fun uploadFileOnCreateTask(file: MPFile<Any>?, attachment: MutableState<String>) {

        viewModelScope.launch {
            val fileToUpload=  file?.getFileByteArray()
            val (fileName, fileExtension) = extractFileNameAndExtension(file?.path!!)

            val response= client.safeRequest<UploadFileResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("${Constants.UPLOAD_URL}/${Constants.env}/uploadFile?userid=${_homeState.value.user?.id}")
                    setBody(MultiPartFormDataContent(
                        formData {
                            append("description", "Ktor logo")
                            append("file", fileToUpload!!, Headers.build {
                                append(HttpHeaders.ContentType, if (fileExtension.contains("pdf"))"application/pdf" else fileExtension)
                                append(HttpHeaders.ContentDisposition, "filename=\"${fileName}.$fileExtension\"")
                            })
                        }
                    )
                    )
                }

            }
            when(response){
                is ApiResponse.Success -> {
                    attachment.value = response.body.filePath?:""
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }
        }
    }

    fun uploadFileOnCreateTask(file: KmpFile?,context:com.mohamedrejeb.calf.core.PlatformContext,block: (String?) -> Unit={}) {

        viewModelScope.launch {
            val fileToUpload=  file?.readByteArray(context)
            val (fileName, fileExtension) = extractFileNameAndExtension(file?.getName(context)?:"")

            val response= client.safeRequest<UploadFileResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Post
                    url("${Constants.UPLOAD_URL}/${Constants.env}/uploadFile?userid=${_homeState.value.user?.id}")
                    setBody(MultiPartFormDataContent(
                        formData {
                            append("description", "Ktor logo")
                            append("file", fileToUpload!!, Headers.build {
                                append(HttpHeaders.ContentType, if (fileExtension.contains("pdf"))"application/pdf" else fileExtension)
                                append(HttpHeaders.ContentDisposition, "filename=\"${fileName}.$fileExtension\"")
                            })
                        }
                    )
                    )
                }

            }
            when(response){
                is ApiResponse.Success -> {
                    block(response.body.filePath)

                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }
        }
    }


    fun deleteProject(projectId:String,block: () -> Unit={}){
        viewModelScope.launch {


            val response =  featuresApi.deleteProject(projectId = projectId,authorization = settings["access_token"])
            if (response.success){
                _homeState.value.projects.remove(_homeState.value.projects.find { it?.id==projectId })
                _homeState.update {
                    it.copy(projects = _homeState.value.projects, loadedProject = true, responseMessage = response.body().message, selectedWorkspaceId = null, selectedWorkspace = null, tasks = arrayListOf())
                }
                block()
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }
    }
    fun deleteTask(taskId:String,subTask:Boolean=false,blocker:Boolean=false){
        viewModelScope.launch {

            val response = toDoApi.deleteTask(taskId = taskId,authorization = settings["access_token"])
            if (response.success){
                if (subTask){
                    _homeState.value.subTasks.remove(_homeState.value.subTasks.find { it.id==taskId })
                    _homeState.update {
                        it.copy(subTasks = _homeState.value.subTasks, responseMessage = response.body().message,)
                    }
                } else if (blocker){
                    val blockers = _homeState.value.task?.blockers?: arrayListOf()
                    val removedBlocker = blockers.find { it.id==taskId }
                    blockers.remove(removedBlocker)
                    _homeState.update {
                        it.copy(task = _homeState.value.task?.copy(blockers = blockers), responseMessage = "Blocker Deleted Successfully")
                    }
                }
                else {
                    val task = _homeState.value.tasks.find { it?.id==taskId }
                    _homeState.value.tasks.remove(task)
                    _homeState.update {
                        it.copy( tasks =_homeState.value.tasks ,responseMessage = response.body().message,)
                    }
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }

    fun createTask(createTaskRequest: CreateTaskRequest){

        viewModelScope.launch {

            val response = toDoApi.createTask(createTaskRequest = createTaskRequest,authorization = settings["access_token"])
            if (response.success){
                _homeState.value.tasks.add(0,response.body().data!!)
                _homeState.update {
                    it.copy(task = response.body().data, responseMessage = response.body().message)
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }
    }

    fun createSubTask(createTaskRequest: CreateTaskRequest,blocker:Boolean,block: () -> Unit={}){
        viewModelScope.launch {

            val response = toDoApi.createTask(createTaskRequest = createTaskRequest,authorization = settings["access_token"])
            if (response.success){
                if (blocker){
                    val blockers = _homeState.value.task?.blockers
                    blockers?.add(0,response.body().data?: TaskItem())
                    _homeState.update {
                        it.copy(blocker = blockers?.get(0))
                    }
                } else {
                    _homeState.value.subTasks.add(0,response.body().data?:TaskItem())
                    _homeState.update {
                        it.copy(subTasks = _homeState.value.subTasks, subTaskCreated = true, responseMessage = response.body().message)
                    }
                }
                block()
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }
    fun showSnackBar(message:String){
        _homeState.update {
            it.copy(responseMessage = message)
        }
    }
    fun updateCategory(category: CategoryItem){
        if (category !in _homeState.value.categories)  _homeState.value.categories.add(0,
            category)

        _homeState.update {
            it.copy(categories = _homeState.value.categories)
        }

    }

    fun updateTask(task: TaskItem,isSubTask:Boolean=false,block:  (TaskItem?) -> Unit={}){

        viewModelScope.launch {
            val response = toDoApi.updateTask(taskId = task.id?:"", updateTaskRequest = UpdateTaskRequest(
                projectId = task.projectId,
                featureId = task.featureId,
                name = task.name,
                description = task.description,
                priority = task.priority,
                taskStatus = task.taskStatus,
                assignedTo = task.assignedTo,
                category = task.category,
                scheduledDate = task.scheduledDate,
                dueDate = task.dueDate,
                startTime = task.startTime,
                stopTime = task.stopTime,
                estimatedTimeInMS = task.estimatedTimeInMS,
                attachments = task.attachments?.toList(), // convert MutableList to List
                capacityValue = task.capacityValue,
                capacityType = task.capacityType,

            )
                ,authorization = settings["access_token"])
            if (response.success){
                if (!isSubTask){
                    _homeState.value.tasks.find { it?.id==response.body().data?.id }.apply {
                        this?.taskStatus= response.body().data?.taskStatus
                        this?.name= response.body().data?.name
                        this?.description= response.body().data?.description
                        this?.priority=response.body().data?.priority
                        this?.assignedTo = response.body().data?.assignedTo
                        this?.assignedToDetails = response.body().data?.assignedToDetails
                        this?.startTime = response.body().data?.startTime
                        this?.stopTime = response.body().data?.stopTime
                        this?.id = response.body().data?.id
                        this?.category = response.body().data?.category
                        this?.capacityValue = response.body().data?.capacityValue
                        this?.capacityType = response.body().data?.capacityType
                        this?.taskStatusDetails = response.body().data?.taskStatusDetails
                    }
                    if (_homeState.value.task?.projectId!=response.body().data?.projectId){
                        _homeState.update {
                            it.copy(task = response.body().data, responseMessage = response.body().message)
                        }
                    } else {
                        _homeState.update {
                            it.copy(task = response.body().data)
                        }
                    }

                } else {
                    _homeState.value.subTasks.find { it.id==response.body().data?.id }.apply {
                        this?.taskStatus=response.body().data?.taskStatus
                        this?.assignedToDetails = response.body().data?.assignedToDetails
                        this?.name = response.body().data?.name
                    }
                    _homeState.update {
                        it.copy(subTasks = _homeState.value.subTasks)
                    }
                }
                block(response.body().data)
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }


    }

    fun createBoard(name:String, columns:List<BoardRequest.Columns>?=null,numPeopleOptions:String?=null,toolUsed:String?=null,block:()->Unit={}){


        viewModelScope.launch {


          val response =  boardsApi.createBoard(BoardRequest(name = name, columns = columns,toolUsed=toolUsed, numPeopleOptions = numPeopleOptions),authorization =settings["access_token"] )
            if (response.success){
                _homeState.value.boards.add(response.body().data)
                _homeState.update {
                    it.copy(boards = _homeState.value.boards, responseMessage = response.body().message)
                }
                block()
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }
    }

    fun resolveBlocker(task: TaskItem, blocker: TaskItem){
        viewModelScope.launch {
            val response = toDoApi.updateTask(taskId = task.id?:"", updateTaskRequest = UpdateTaskRequest(
                projectId = task.projectId,
                featureId = task.featureId,
                name = task.name,
                description = task.description,
                priority = task.priority,
                taskStatus = task.taskStatus,
                assignedTo = task.assignedTo,
                category = task.category,
                scheduledDate = task.scheduledDate,
                dueDate = task.dueDate,
                startTime = task.startTime,
                stopTime = task.stopTime,
                estimatedTimeInMS = task.estimatedTimeInMS,
                attachments = task.attachments?.toList() // convert MutableList to List
            )
                ,authorization = settings["access_token"])
            if (response.success){
                _homeState.value.task?.blockers?.find { it.id==blocker.id }.apply {
                    this?.taskStatus = response.body().data?.taskStatus
                    this?.name = response.body().data?.name
                    this?.assignedToDetails = response.body().data?.assignedToDetails
                }
                _homeState.update {
                    it.copy(task = _homeState.value.task,responseMessage = "Blocker Updated Successfully")
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }


        /*
        viewModelScope.launch {
            val response= client.safeRequest<TaskResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Put
                    path("${Constants.env}/tasks/updateTask/${task.id}")
                }
                contentType(ContentType.Application.Json)
                setBody(task)
            }
            when(response){
                is ApiResponse.Success -> {
                    _homeState.value.task?.blockers?.find { it.id==blocker.id }.apply {
                        this?.taskStatus = response.body.data?.taskStatus
                        this?.name = response.body.data?.name
                        this?.assignedToDetails = response.body.data?.assignedToDetails
                    }
                    _homeState.update {
                        it.copy(task = _homeState.value.task,responseMessage = "Blocker Updated Successfully")
                    }
                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
        */
    }
    fun getMyTasks(){
        viewModelScope.launch {
            val task =  client.get {
                url {
                    //  method = HttpMethod.Delete
                    path("${Constants.env}/tasks/getMyTasks")
                }
                contentType(ContentType.Application.Json)
            }.body<GetMyTasksResponse>()
          /*  _homeState.update {
                it.copy(tasks = task.data)
            } */
        }
    }
    fun acceptWorkspaceInvite(boardId: String?){

    viewModelScope.launch {
            val response =  boardsApi.responseToBoardInviteRequest(BoardMemberInviteUserResponseRequest(
                userResponse = BoardMemberInviteUserResponseRequest.UserResponse.Accepted,
                boardId = boardId),authorization =settings["access_token"] )
            if (response.success){
                _homeState.update {
                    it.copy(acceptedBoardInvite = response.body().code, responseMessage = response.body().message)
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                        acceptWorkspaceInvite(boardId)
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

}


    fun sendBoardInvite(emails: List<String>, boardId: String?){
        viewModelScope.launch {
            val response =  boardsApi.inviteBoardMembers(BoardMemberInviteRequest(
                emails = emails,
                boardId = boardId,
                sharedLink = "${Constants.WEBSITE_URL}/#/inviteToWorkspace?boardId=${boardId}"),
                authorization =settings["access_token"]
            )
            if (response.success){
                _homeState.update {
                    it.copy(acceptedBoardInvite = response.body().message, responseMessage = response.body().message)
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }

    fun getBoardMembers(boardId: String){
        viewModelScope.launch {


            val response =  boardsApi.getBoardMembers(boardId = boardId,authorization =settings["access_token"] )
            if (response.success){
                _homeState.update {
                    it.copy(boardMembers = response.body().data?.toMutableList()?: arrayListOf())
                }
            } else {

                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                        getBoardMembers(boardId)
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }
    fun getWorkspaceTasks(nextPage:Boolean=false,taskStatus:String?=null,priority:String?=null,assignedTo:String?=null,dueDate:String?=null,block: () -> Unit={}){
        viewModelScope.launch {
            val response = toDoApi.getProjectTasks(projectId = _homeState.value.selectedWorkspaceId?:"",authorization = settings["access_token"],
                taskStatus = taskStatus, priority = priority, assignedTo = assignedTo, dueDate = dueDate)
            if (response.success){
                if (nextPage){
                    _homeState.value.tasks.addAll(response.body().data?: arrayListOf())
                    _homeState.update {
                        it.copy(tasks = _homeState.value.tasks.distinct().toMutableList(),loadedTasks=true, nextPageToken = response.body().pagination?.nextPageToken)
                    }
                } else {
                    _homeState.update {
                        it.copy(tasks = response.body().data?.toMutableList()?: arrayListOf(),loadedTasks=true, nextPageToken = response.body().pagination?.nextPageToken)
                    }
                }
                block()
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                        getWorkspaceTasks()
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }
        /*
        viewModelScope.launch {
            val response= client.safeRequest<GetMyTasksResponse, ErrorResponse> {
                url {
                    method = HttpMethod.Get
                    path("${Constants.env}/tasks/getProjectTasks/${_homeState.value.selectedWorkspaceId}")
                    if (nextPage&&_homeState.value.nextPageToken!=null) parameters.append("nextPageToken",_homeState.value.nextPageToken?:"")
                    if (!taskStatus.isNullOrEmpty()) parameters.append("taskStatus",taskStatus)
                    if (!priority.isNullOrEmpty()) parameters.append("priority",priority)
                    if (!assignedTo.isNullOrEmpty()) parameters.append("assignedTo",assignedTo)
                    if (!dueDate.isNullOrEmpty()) parameters.append("dueDate",dueDate)
                }
                contentType(ContentType.Application.Json)

            }
            when(response){
                is ApiResponse.Success -> {
                    if (nextPage){
                        _homeState.value.tasks.addAll(response.body.data)
                        _homeState.update {
                            it.copy(tasks = _homeState.value.tasks.distinct().toMutableList(),loadedTasks=true, nextPageToken = response.body.pagination?.nextPageToken)
                        }
                    } else {
                        _homeState.update {
                            it.copy(tasks = response.body.data.toMutableList(),loadedTasks=true, nextPageToken = response.body.pagination?.nextPageToken)
                        }
                    }
                    block()

                }
                is ApiResponse.Error.GenericError -> {
                    Napier.e("GenericError $response")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
                is ApiResponse.Error.HttpError -> {
                    Napier.e("HttpError $response")
                    if (response.errorBody?.contains("Unauthorized") == true){
                        _homeState.update {
                            it.copy(
                                responseMessage = "Please Wait , Refreshing your Credentials", loadedProject = true, projects = arrayListOf()
                            )
                        }
                        refreshTokenAndRetry{
                            getWorkspaceTasks()
                        }
                    } else {
                        _homeState.update {
                            it.copy(
                                errorMessage = response.errorBody)
                        }
                    }
                }
                is ApiResponse.Error.SerializationError -> {
                    Napier.e("SerializationError ${response.errorMessage}")
                    _homeState.update {
                        it.copy(
                            errorMessage = response.errorMessage)
                    }
                }
            }

        }
        */
    }

    fun getTaskDetails(taskId: String?,block: (TaskItem?) -> Unit={}){

        viewModelScope.launch {
            val response = toDoApi.getTaskDetails(taskId = taskId?:"",authorization = settings["access_token"])
            if (response.success){
                if (response.body().data?.category !in _homeState.value.categories)  _homeState.value.categories.add(0,
                    response.body().data?.category?: CategoryItem()
                )

                _homeState.update {
                    it.copy(task = response.body().data,subTasks=response.body().subTasks?.toMutableList()?: arrayListOf(), categories = _homeState.value.categories)
                }
                block(response.body().data)
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {
                        getTaskDetails(taskId,{})
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }

    fun saveUser(data: UserRegistration) {
        _homeState.update {
            it.copy(user = data)
        }
    }



    fun extractFileNameAndExtension(filePath: String): Pair<String, String> {
        val separatorIndex = filePath.lastIndexOf('/')
        val dotIndex = filePath.lastIndexOf('.')

        return if (separatorIndex != -1 && (dotIndex == -1 || separatorIndex > dotIndex)) {
            // If there's no dot or the last separator is after the last dot, return the full file name and an empty extension
            Pair(filePath.substring(separatorIndex + 1), "")
        } else {
            // Otherwise, return the file name and its extension
            Pair(filePath.substring(separatorIndex + 1, dotIndex), filePath.substring(dotIndex + 1))
        }
    }

    fun createComment(text: String,taskId: String?,block: (Comment) -> Unit={}){

        viewModelScope.launch {
            val response = commentsApi.createComment(commentRequest = CommentRequest(text = text, taskId = taskId),authorization = settings["access_token"])
            if (response.success){
                val newComments=  _homeState.value.task?.comments
                newComments?.data?.add(response.body().data?: Comment())
                _homeState.update {
                    it.copy(comment = response.body().data, task = _homeState.value.task?.copy(comments = newComments), responseMessage = response.body().message)
                }
                block(response.body().data?: Comment())
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }
    }


    fun updateComment(comment: Comment){

        viewModelScope.launch {
            val response = commentsApi.updateComment(commentId = comment.id?:"",commentRequest = CommentRequest(text = comment.text, taskId = comment.taskId),authorization = settings["access_token"])
            if (response.success){
                val newComments=  _homeState.value.task?.comments
                newComments?.data?.find { it.id==response.body().data?.id }.apply {
                    this?.text = response.body().data?.text
                }
                _homeState.update {
                    it.copy(comment = response.body().data, task = _homeState.value.task?.copy(comments = newComments))
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }

    fun deleteComment(comment: Comment?){

        viewModelScope.launch {
            val response = commentsApi.deleteComment(commentId = comment?.id?:"",authorization = settings["access_token"])
            if (response.success){
                val newComments=  _homeState.value.task?.comments
                newComments?.data?.remove(comment)
                _homeState.update {
                    it.copy(task = _homeState.value.task?.copy(comments = newComments), responseMessage = response.body().message)
                }
            } else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry {

                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }
            }
        }

    }


    fun updateUser(user: UserRegistration,block: () -> Unit={}){
        viewModelScope.launch {

            val response = registrationApi.updateUser(userUpdateRequest = UserUpdateRequest(
                userName = user.userName,
                name = user.name,
                focus = user.focus,
                role = user.role,
                areasOfInterest = user.areasOfInterest?.firstOrNull(),
                avatar = user.avatar
            ),authorization = settings["access_token"])
            if (response.success){
                _homeState.update {
                    it.copy(user = response.body().data, responseMessage = response.body().message)
                }
                block()
            }
            else {
                if (response.body().message=="Unauthorized"){
                    _homeState.update {
                        it.copy(
                            responseMessage = "Please Wait, Refreshing your Credentials",
                            loadedProject = true,
                            projects = arrayListOf()
                        )
                    }

                    refreshTokenAndRetry{
                        getUser()
                    }
                } else {
                    _homeState.update {
                        it.copy(errorMessage = response.body().message)
                    }
                }


            }



        }

    }

}
