package org.company.app.presentation.ui


import Route
import Whiteboard
import androidx.compose.foundation.*
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import kotlinx.serialization.json.Json
import org.company.app.presentation.component.*
import org.company.app.presentation.viewmodel.HomeViewModel
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.koin.compose.koinInject
import org.openapitools.client.models.InfiniteBoardDataType

object LoopComponentScreen :Screen {


    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val viewModel= koinInject<HomeViewModel>()
        val homeState = viewModel.homeState.collectAsState()
        if (homeState.value.navigateToHome){
            navigator.pop()
            viewModel.navigateToHome()
        }
        LoopComponentScreenContent()
    }
    @OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3WindowSizeClassApi::class,
        ExperimentalFoundationApi::class, ExperimentalComposeUiApi::class
    )
    @Composable
    fun LoopComponentScreenContent(){
        val windowSizeClass = calculateWindowSizeClass()
        val useNavRail = windowSizeClass.widthSizeClass > WindowWidthSizeClass.Compact
        val addIdea = remember { mutableStateOf(false) }
        if (addIdea.value){
            CreateIdeaDialog(onDismiss = {addIdea.value=false})
        }
        Column(
            modifier = Modifier.fillMaxSize(),

            ) {



            Column(modifier = Modifier.padding(top = 40.dp)) {

                EditableScreen()
               // Whiteboard()



            }













        }
    }
}









@Composable
fun EditableScreen() {

    val viewModel = koinInject<HomeViewModel>()
    val homeState = viewModel.homeState.value
    LaunchedEffect(Unit){
      //  viewModel.getInfiniteBoard()
    }
    var textFields by remember { mutableStateOf(listOf<TextFieldState>()) }



    // table
    /*
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        androidx.compose.material.Text(
            text = "Search Result (Multiplatform)",
            fontSize = 18.sp,
            fontWeight = FontWeight.Bold,
            modifier = Modifier.padding(top = 10.dp)
        )

        val headers = listOf(
            "",
            "#",
            "Start Time",
            "End Time",
            "Duration",
            "#Trips",
            "Total Fare",
            "Fare (JR)",
            "Fare (Bus)",
            "Fare (Others)",
            "Walking",
            "Waiting",
            "Stops"
        )

        Table(
            modifier = Modifier
                .fillMaxSize()
                .padding(10.dp),
            columnCount = headers.size,
            rowCount = result.size + 1,
            stickyRowCount = 2,
            stickyColumnCount = 2,
            maxCellWidthDp = 320.dp
        ) { rowIndex, columnIndex ->
            val header = headers[columnIndex]
            if (rowIndex == 0) {
                HeaderCell(header)
            } else {
                val r = result[rowIndex - 1]
                when (header) {
                    "" -> ToggleCell(rowIndex - 1,checkedRows)
                    "#" -> ContentCell(rowIndex.toString(), checkedRows.containsKey(rowIndex - 1))
                    "Start Time" -> ContentCell(r.summary.startAt, checkedRows.containsKey(rowIndex - 1))
                    "End Time" -> ContentCell(r.summary.endAt, checkedRows.containsKey(rowIndex - 1))
                    "Duration" -> ContentCell(r.summary.endAt, checkedRows.containsKey(rowIndex - 1))
                    "#Trips" -> ContentCell(r.summary.numOfTrips.toString(), checkedRows.containsKey(rowIndex - 1))
                    "Total Fare" -> ContentCell(r.summary.totalFare.toString(), checkedRows.containsKey(rowIndex - 1))
                    "Fare (JR)" -> ContentCell(r.summary.fares?.toString() ?: "-", checkedRows.containsKey(rowIndex - 1))
                    "Fare (Bus)" -> ContentCell(r.summary.fares?.toString() ?: "-", checkedRows.containsKey(rowIndex - 1))
                    "Fare (Others)" -> ContentCell(r.summary.fares?.toString() ?: "-", checkedRows.containsKey(rowIndex - 1))
                    "Walking" -> ContentCell(r.summary.walkingSeconds.toString(), checkedRows.containsKey(rowIndex - 1))
                    "Waiting" -> ContentCell(r.summary.waitingSeconds.toString(), checkedRows.containsKey(rowIndex - 1))
                    "Stops" -> ContentCell(r.keyStops.firstOrNull()?.name?:"", checkedRows.containsKey(rowIndex - 1), Alignment.CenterStart)
                }
            }
        }



    }
    */






    Box(
        modifier = Modifier
            .fillMaxSize()
            .pointerInput(Unit) {
                detectTapGestures(
                    onPress = { offset ->
                        // Remove all empty TextFields
                        textFields = textFields.filter { it.value.isNotEmpty() }

                        // Add a new TextField at the start position
                        textFields = textFields + TextFieldState(0f, offset.y, "")

                    }
                )
            }
    ) {
        val density = LocalDensity.current
        val focusRequester = remember { FocusRequester() }
        textFields.forEach { textField ->
            val basicRichTextState = rememberRichTextState()
            LoopComponent(Modifier.focusRequester(focusRequester)
                .offset(
                    x = with(density) { 0f.toDp() }, // Always start at 0
                    y = with(density) { textField.y.toDp() }
                )
                .fillMaxWidth(),basicRichTextState,textField, onClick = {
                println(textFields.toString())
                viewModel.createInfiniteBoard(mapTextFieldStateToData(textFields))
            }, createTable = {
                viewModel.createInfiniteBoard(data = mapTextFieldStateToData(textFields), tableData = it)
            })

            textFields = textFields.map {
                if (it === textField) it.copy(value = basicRichTextState.annotatedString.text) else it
            }



        }
        if (textFields.isNotEmpty()){
            LaunchedEffect(textFields.lastOrNull()){
                focusRequester.requestFocus()
            }
        }

    }
}

data class TextFieldState(val x: Float, val y: Float, var value: String = "", var type:String="text")

fun mapTextFieldStateToData(textFieldStateList: List<TextFieldState>): List<InfiniteBoardDataType> {
    return textFieldStateList.map { textFieldState ->
        InfiniteBoardDataType(
            type = textFieldState.type,
            value = textFieldState.value,

        )
    }
}


