package org.company.app.util

object Constants {
    const val BASE_URL = "https://50adylbmr7.execute-api.ap-south-1.amazonaws.com/staging"
    const val UPLOAD_URL = "https://1xnv99l44b.execute-api.ap-south-1.amazonaws.com"
    const val env = "staging"
    const val WEBSITE_URL = "https://staging.zifoco.zimozi.xyz"
    const val PARAM_AUTH = "Authorization"
    var token =  ""
    const val FirebaseKey = "AIzaSyCTXTmdrxWL44BQPhz_8ARYYhqxEKdSyjs"
    const val REPO_OWNER = "ZiFOCO"

    const val REPO_NAME = "ZiPages"

    const val GITHUB_BASE_URL = "https://api.github.com/repos/$REPO_OWNER/$REPO_NAME/contents"
}
